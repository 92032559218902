<template>
  <div class="container-fluid myvideos-content">
    <div class="fave-wrapper">
      <div class="fave-row" v-if="favoriteVideos != ''">
        <div class="row">
          <!-- Media Player Section -->
          <div class="col-12 col-md-12 col-lg-12 col-xl-9"
               :class="{'rmv-rgt-pd': breakpoint !== 'xs' &&
                 breakpoint !== 'sm' && breakpoint !== 'md' &&
                 breakpoint !== 'lg' }">
            <div class="media-container text-center"
                 :style="{height: `${mediaContainerHeight}px`}">
              <div :class="`wistia_embed wistia_async_${wistiaId}
                      playlistLinks=auto videoFoam=true`"
                   id="media-player"
                   style="width: 100%; height: 60vh;">
              </div>
            </div>
          </div>

          <!-- Playlist Section -->
          <div class="col-12 col-md-12 col-lg-12 col-xl-3"
               :class="{
                 'rmv-lft-pd': breakpoint !== 'xs' && breakpoint !== 'sm' &&
                   breakpoint !== 'md' && breakpoint !== 'lg',
                 'd-flex': breakpoint !== 'xs' && breakpoint !== 'sm' &&
                   breakpoint !== 'md' && breakpoint !== 'lg'}">
            <ol class="fave-list"
                :style="{height: `${mediaContainerHeight}px`}">
              <li :class="{active: activeMedia === fave.wistia_id}"
                  :id="'list-' + fave.wistia_id"
                  v-for="(fave, index) in favoriteVideos"
                  :key="index">

                <!-- Remove Icon -->
                <div class="remove-btn"
                     @click.prevent="removeAction(fave.wistia_id,
                       fave.media_id, fave.media_title)"
                     title="Remove Favorite">
                  <i class="fa fa-trash-alt remove-icon"></i>
                </div>

                <a class="fave-link"
                   :id="'link-'+fave.wistia_id"
                   :href="'#wistia_' + fave.wistia_id + '?autoPlay=true'"
                   @click="setActiveMedia(fave.wistia_id)">

                  <div class="fave-content">
                    <div class="row">
                      <!-- Media Thumbnail -->
                      <div class="col-12 col-sm-4 col-md-4 col-xl-4
                             fave-thumbnail"
                           :class="{'rmv-rgt-pd': breakpoint !== 'xs' &&
                             breakpoint !== 'sm'}">
                        <img class="img-fluid"
                             :class="{'img-w': breakpoint === 'xs' ||
                               breakpoint === 'sm'}"
                             :id="'img-' + fave.media_id"
                             :src="`${fave.thumbnail}&image_play_button=true`"
                             alt="play-icon"
                             @error="imgDefault(fave.media_id)"/>
                      </div>

                      <!-- Media Info -->
                      <div class="col-12 col-sm-8 col-md-8 col-xl-8 fave-text"
                           :class="{'rmv-lft-pd': breakpoint !== 'xs' &&
                             breakpoint !== 'sm'}">
                        <p> {{ fave.media_title | truncate(20) }} </p>
                        <p> {{ fave.media_length | formatTime }} </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class="" v-else>
        <no-content v-if="!loading">
          <div class="">
            <h3>This space looks empty</h3>
            <p class="mb-0">Fill it up by liking clips from our
              <strong>
                <router-link to="/browse/categories">library</router-link>
              </strong>!
            </p>
          </div>
        </no-content>
      </div>
    </div>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import FilterMixin from '@/mixins/FilterMixin';
  import MyMediaMixin from '@/mixins/MyMediaMixin';
  import _ from 'lodash';

  const NoContent = () => import('@/components/user/common/NoContent');
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name       : 'MyVideos',
    components : {
      NoContent,
    },
    data() {
      return {
        favoriteVideos : [],
        wistiaId       : null,
        activeMedia    : null,
        emptyFavorite  : '',
        loading        : true,
        secondsWatched : 0,
      };
    },
    mounted() {
      setTimeout(() => {
        const wistiaScript = document.createElement('script');
        wistiaScript.setAttribute('src',
                                  '//fast.wistia.com/assets/external/E-v1.js');
        wistiaScript.setAttribute('async', '');
        document.head.appendChild(wistiaScript);
      }, 1000);

      // get active wistia id
      const that = this;
      window._wq = window._wq || [];
      // eslint-disable-next-line no-undef
      _wq.push({ id      : '_all', onReady : function(video) {
        video.bind('play', function() {
          var activeVideo = video.hashedId();
          that.setActiveMedia(activeVideo);
        });
      }});

      this.getMyFavorites();
    },
    computed : {
      ...mapGetters({
        user : 'user/user',
      }),
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      /**
       * Get All User's Media Favorites
       */
      getMyFavorites() {
        this.loading = true;
        this.$http.get('api/myvideos')
          .then((response) => {
            this.favoriteVideos = response.data;
            this.wistiaId = this.favoriteVideos[0].wistia_id;

            // set initial active media
            this.setActiveMedia(this.wistiaId);
            this.loading = false;

          }).catch((err) => {
            this.emptyFavorite = err.response.data.msg;
            this.loading = false;
          });
      },

      /**
       * Remove Media from User's Favorites
       *
       * @param wistiaId Media's Wistia ID
       * @param mediaId Assigned H3 Media ID
       */
      removeFavorite(wistiaId, mediaId, mediaTitle) {
        // get element & child link to remove
        const element = document.getElementById('list-' + wistiaId);
        const elementLink = document.getElementById('link-' + wistiaId);

        // if element to remove is active, next or
        //  prev media should be played
        if (element.classList.contains('active')) {
          const links = document.getElementsByClassName('fave-link');

          for (let i = 0; i < links.length; i++) {
            if (links[i] == elementLink && links.length != 1) {
              // determine if next element is true then
              //  next element will be played
              (links[i + 1]) ? links[i + 1].click() : links[i - 1].click();
            }
          }

          if (links.length === 1) {
            //TODO: emptyFavorite value should be from err.response.data.msg
            // in getMyFavorties()
            this.favoriteVideos = [];
            this.emptyFavorite = 'No videos found for playlist';
          }
        }

        // remove element in UI
        element.remove();

        // remove from database
        this.$http.delete('api/myvideos', {
          data : {
            'mediaId' : mediaId,
          },
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : mediaTitle + ' has been removed from Favorites',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Set Active Media
       *
       * @param activeWistiaId Active Wistia ID
       */
      setActiveMedia(activeWistiaId) {
        this.activeMedia = activeWistiaId;

        const currentVid = _.find(this.favoriteVideos, function(o){
          return o.wistia_id == activeWistiaId;
        });

        this.$analytics.fbq.event('ViewContent', {
          'page'        : 'my-favorites',
          'platform'    : 'website',
          'user_id'     : this.$store.getters['user/user'].user_id,
          'video_title' : currentVid.media_title,
          'video_id'    : currentVid.media_id,
        });
        window._wq = window._wq || [];
        // eslint-disable-next-line no-undef
        _wq.push({
          id        : activeWistiaId,
          onHasData : (video) => {
            video.unbind('secondchange')
              .bind('secondchange', () => {
                this.secondsWatched = video.secondsWatched();
              });
          },
          onReady : (video) => {
            const that = this;
            video.bind('end', function() {
              if (that.secondsWatched >= 60) {
                let event = 'WATCHED_VIDEO';
                if (that.user.first_video == 0)
                  event = 'FIRST_VIDEO'
                
                that.$http.post('api/user/level', {
                  id    : that.user.user_id,
                  event : event,
                }).then(() => {
                  if (event === 'FIRST_VIDEO') {
                    that.$http.put('api/user', {
                      'updateFields' : {
                        // eslint-disable-next-line camelcase
                        first_video : 1,
                      },
                    }).then(() => {
                      localStorage.levelUpAction = 'FIRST_VIDEO';
                      that.getUpdatedUser();
                    })
                  } else {
                    localStorage.levelUpAction = 'AFTER_VIDEO';
                    that.getUpdatedUser();
                  }

                });
              }
            })
          },
        },
        );
      },

      /**
       * Set Default Image Thumbnail
       *
       * @param mediaId Assigned H3 Media ID
       */
      imgDefault(mediaId) {
        const element = document.getElementById('img-' + mediaId);
        element.setAttribute('src', '../default_thumbnail_img.jpg');
      },

      /***
       * Show Remove favorite confirmation dialog
       * @param wistiaId
       * @param mediaId
       * @param mediaTitle
       */
      removeAction(wistiaId, mediaId, mediaTitle) {
        this.$modal.show('dialog', {
          title   : 'Remove Favorite',
          text    : 'Are you sure you want to remove video as favorite?',
          buttons : [
            {
              title   : 'Yes',
              handler : () => {
                this.removeFavorite(wistiaId, mediaId, mediaTitle);
                this.$modal.hide('dialog');
              },
            },
            {
              title : 'No',
            },
          ],
        })
      },
    },
    mixins : [
      BreakpointMixin,
      FilterMixin,
      MyMediaMixin,
    ],
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/my-videos";
</style>
