var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid myvideos-content"},[_c('div',{staticClass:"fave-wrapper"},[(_vm.favoriteVideos != '')?_c('div',{staticClass:"fave-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-9",class:{'rmv-rgt-pd': _vm.breakpoint !== 'xs' &&
               _vm.breakpoint !== 'sm' && _vm.breakpoint !== 'md' &&
               _vm.breakpoint !== 'lg' }},[_c('div',{staticClass:"media-container text-center",style:({height: `${_vm.mediaContainerHeight}px`})},[_c('div',{class:`wistia_embed wistia_async_${_vm.wistiaId}
                    playlistLinks=auto videoFoam=true`,staticStyle:{"width":"100%","height":"60vh"},attrs:{"id":"media-player"}})])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-3",class:{
               'rmv-lft-pd': _vm.breakpoint !== 'xs' && _vm.breakpoint !== 'sm' &&
                 _vm.breakpoint !== 'md' && _vm.breakpoint !== 'lg',
               'd-flex': _vm.breakpoint !== 'xs' && _vm.breakpoint !== 'sm' &&
                 _vm.breakpoint !== 'md' && _vm.breakpoint !== 'lg'}},[_c('ol',{staticClass:"fave-list",style:({height: `${_vm.mediaContainerHeight}px`})},_vm._l((_vm.favoriteVideos),function(fave,index){return _c('li',{key:index,class:{active: _vm.activeMedia === fave.wistia_id},attrs:{"id":'list-' + fave.wistia_id}},[_c('div',{staticClass:"remove-btn",attrs:{"title":"Remove Favorite"},on:{"click":function($event){$event.preventDefault();return _vm.removeAction(fave.wistia_id,
                     fave.media_id, fave.media_title)}}},[_c('i',{staticClass:"fa fa-trash-alt remove-icon"})]),_c('a',{staticClass:"fave-link",attrs:{"id":'link-'+fave.wistia_id,"href":'#wistia_' + fave.wistia_id + '?autoPlay=true'},on:{"click":function($event){return _vm.setActiveMedia(fave.wistia_id)}}},[_c('div',{staticClass:"fave-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 col-md-4 col-xl-4 fave-thumbnail",class:{'rmv-rgt-pd': _vm.breakpoint !== 'xs' &&
                           _vm.breakpoint !== 'sm'}},[_c('img',{staticClass:"img-fluid",class:{'img-w': _vm.breakpoint === 'xs' ||
                             _vm.breakpoint === 'sm'},attrs:{"id":'img-' + fave.media_id,"src":`${fave.thumbnail}&image_play_button=true`,"alt":"play-icon"},on:{"error":function($event){return _vm.imgDefault(fave.media_id)}}})]),_c('div',{staticClass:"col-12 col-sm-8 col-md-8 col-xl-8 fave-text",class:{'rmv-lft-pd': _vm.breakpoint !== 'xs' &&
                           _vm.breakpoint !== 'sm'}},[_c('p',[_vm._v(" "+_vm._s(_vm._f("truncate")(fave.media_title,20))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("formatTime")(fave.media_length))+" ")])])])])])])}),0)])])]):_c('div',{},[(!_vm.loading)?_c('no-content',[_c('div',{},[_c('h3',[_vm._v("This space looks empty")]),_c('p',{staticClass:"mb-0"},[_vm._v("Fill it up by liking clips from our "),_c('strong',[_c('router-link',{attrs:{"to":"/browse/categories"}},[_vm._v("library")])],1),_vm._v("! ")])])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }